import firebase from './firebaseConfig';
import React, { useEffect, useState } from 'react';
import { Unsubscribe, User } from 'firebase';

export interface AuthenticationState {
	authStatusReported: boolean,
	userSignedIn: boolean,
	user: User | null
}

const defaultAuthenticationState: AuthenticationState = {
	authStatusReported: false,
	userSignedIn: false,
	user: null
};

export const AuthenticationStore = React.createContext<AuthenticationState>(defaultAuthenticationState);

interface AuthenticationProviderProps {
	children: React.ReactNode
}

const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({ children }) => {
	const [state, setState] = useState<AuthenticationState>(defaultAuthenticationState);
	const { authStatusReported } = state;

	useEffect((): Unsubscribe => {
		return firebase.auth().onAuthStateChanged((user) => {
			setState({
				authStatusReported: true,
				userSignedIn: !!user,
				user
			});
		});
	}, []);

	return (
		<AuthenticationStore.Provider value={state}>
			{authStatusReported && children}
		</AuthenticationStore.Provider>
	);
};

export default AuthenticationProvider;
