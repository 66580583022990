import React, { useCallback, useContext, useState } from 'react';
import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonContent,
	IonHeader,
	IonInput,
	IonItem,
	IonList,
	IonPage,
	IonSpinner,
	IonTitle,
	IonToast,
	IonToolbar
} from '@ionic/react';
import './Login.css';
import { AuthenticationState, AuthenticationStore } from './AuthenticationContext';
import { Redirect } from 'react-router';
import firebase from './firebaseConfig';

const Login: React.FC = () => {
	const { userSignedIn }: AuthenticationState = useContext(AuthenticationStore);
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const signIn = useCallback(async (): Promise<void> => {
		try {
			setLoading(true);
			await firebase.auth().signInWithEmailAndPassword(email, password);
			setEmail('');
			setPassword('');
			setError(false);
		} catch (exception) {
			setError(true);
		} finally {
			setLoading(false);
		}
	}, [email, password]);

	return userSignedIn ? <Redirect to="/home"/> : (
		<IonPage>
			<IonHeader><IonToolbar><IonTitle>Login</IonTitle></IonToolbar></IonHeader>

			<IonContent color="primary" className="ion-padding-top">
				<IonCard className="login-card">
					<IonCardHeader>
						<IonCardTitle color="primary" className="ion-text-center logo-font">EmmaY</IonCardTitle>
					</IonCardHeader>
					<IonCardContent>
						{error && (
							<IonToast
								isOpen={error}
								duration={2000}
								onDidDismiss={() => setError(false)}
								color="danger"
								message="Login fehlgeschlagen."
							/>
						)}
						<IonList>
							<IonItem className="ion-margin-vertical">
								<IonInput
									onIonChange={(e) => setEmail(e.detail.value!)}
									type="email"
									value={email}
									placeholder="E-Mail ..."
									clearInput
								/>
							</IonItem>
							<IonItem className="ion-margin-vertical">
								<IonInput
									onIonChange={(e) => setPassword(e.detail.value!)}
									type="password"
									value={password}
									placeholder="Passwort ..."
									clearInput
								/>
							</IonItem>
						</IonList>
						<IonButton onClick={signIn} disabled={loading}>
							{loading ? <IonSpinner/> : 'Login'}
						</IonButton>
						<IonButton fill="clear">Registrieren</IonButton>
					</IonCardContent>
				</IonCard>
			</IonContent>
		</IonPage>
	);
};

export default Login;
