import Page from '../Page';
import React from 'react';

const StartPage: React.FC = () => {
	return (
		<Page>
			<p>Start</p>
		</Page>
	);
};

export default StartPage;
