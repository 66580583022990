import React from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';
import Login from './authentication/Login';
import Menu from './navigation/Menu';
import AuthenticationProvider from './authentication/AuthenticationContext';
import StartPage from './pages/Start/StartPage';
import Page from './pages/Page';
import AccountPage from './pages/Account/AccountPage';
import ProtectedRoute from './authentication/ProtectedRoute';

const App: React.FC = () => {
	return (
		<AuthenticationProvider>
			<IonApp>
				<IonReactRouter>
					<IonSplitPane contentId="main">
						<Menu/>
						<IonRouterOutlet id="main">
							<Redirect exact from="/" to="/login"/>
							<Route path="/login" component={Login} exact/>
							<ProtectedRoute path="/home" component={StartPage} exact/>
							<ProtectedRoute path="/account" component={AccountPage} exact/>
							<Route render={() => <Page><h1>Not found</h1></Page>}/>
						</IonRouterOutlet>
					</IonSplitPane>
				</IonReactRouter>
			</IonApp>
		</AuthenticationProvider>
	);
};

export default App;
