import {
	IonButton,
	IonContent,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonMenu,
	IonMenuToggle,
	IonNote
} from '@ionic/react';
import React, { useCallback, useContext } from 'react';
import './Menu.css';
import { AuthenticationState, AuthenticationStore } from '../authentication/AuthenticationContext';
import { useLocation } from 'react-router-dom';
import appPageService from './AppPage';
import firebase from '../authentication/firebaseConfig';
import { logOut } from 'ionicons/icons';

const Menu: React.FC = () => {
	const { userSignedIn, user }: AuthenticationState = useContext(AuthenticationStore);
	const { pathname } = useLocation();
	const logout = useCallback(async (): Promise<void> => {
		await firebase.auth().signOut();
	}, []);

	return userSignedIn ? (
		<IonMenu contentId="main" type="overlay">
			<IonContent>
				<IonList id="navigation">
					<IonListHeader>Navigation</IonListHeader>
					<IonNote>{user?.email}</IonNote>
					{appPageService.appPages.map((appPage, index) => {
						return (
							<IonMenuToggle key={index} autoHide={false}>
								<IonItem
									routerLink={appPage.url}
									routerDirection="none"
									lines="none"
									detail={false}
									className={appPage.url === pathname ? 'selected' : ''}
								>
									<IonIcon slot="start" icon={appPage.iosIcon}/>
									<IonLabel>{appPage.title}</IonLabel>
								</IonItem>
							</IonMenuToggle>
						);
					})}
				</IonList>
				<div className="ion-margin-top">
					<IonButton fill="clear" color="danger" onClick={logout}>
						<IonIcon slot="start" icon={logOut}/> Logout
					</IonButton>
				</div>
			</IonContent>
		</IonMenu>
	) : <IonMenu contentId="main" type="overlay" style={{ display: 'none' }}/>;
};

export default Menu;
