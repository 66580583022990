import * as firebase from 'firebase/app';
import 'firebase/auth';

firebase.initializeApp({
	apiKey: 'AIzaSyACMOksTob745cWEhLQnlCu2Uudx0oEDEI',
	authDomain: 'emma-app-k.firebaseapp.com',
	databaseURL: 'https://emma-app-k.firebaseio.com',
	projectId: 'emma-app-k',
	storageBucket: 'emma-app-k.appspot.com',
	messagingSenderId: '656351012969',
	appId: '1:656351012969:web:ab0f83ffc90aca45189af8',
	measurementId: 'G-KNMNJBEG3G'
});

export default firebase;
