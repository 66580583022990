import { homeOutline, homeSharp, personOutline, personSharp } from 'ionicons/icons';

export interface AppPage {
	url: string;
	iosIcon: string;
	mdIcon: string;
	title: string;
}

const appPages: AppPage[] = [
	{
		title: 'Start',
		url: '/home',
		iosIcon: homeOutline,
		mdIcon: homeSharp
	},
	{
		title: 'Mein Account',
		url: '/account',
		iosIcon: personOutline,
		mdIcon: personSharp
	}
];

class AppPageService {
	constructor(appPages: AppPage[]) {
		this._appPages = appPages;
	}

	private _appPages: AppPage[];

	get appPages(): AppPage[] {
		return this._appPages;
	}

	getPageForPath(path: string): AppPage | undefined {
		return this._appPages.find((page: AppPage) => path === page.url);
	}
}

export default new AppPageService(appPages);
