import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import './Page.css';
import appPageService, { AppPage } from '../navigation/AppPage';

interface PageProps {
	children: React.ReactNode
}

const Page: React.FC<PageProps> = ({ children }) => {
	const { pathname } = useLocation();
	const page: AppPage | undefined = appPageService.getPageForPath(pathname);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton/>
					</IonButtons>
					<IonTitle>{page?.title}</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent>
				<IonHeader collapse="condense">
					<IonToolbar>
						<IonTitle size="large">{page?.title}</IonTitle>
					</IonToolbar>
				</IonHeader>
				{children}
			</IonContent>
		</IonPage>
	);
};

export default Page;
