import React, { useContext } from 'react';
import { AuthenticationState, AuthenticationStore } from './AuthenticationContext';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute: React.FC<any> = ({ component, ...rest }: any) => {
	const { userSignedIn }: AuthenticationState = useContext(AuthenticationStore);

	return <Route {...rest} render={(props: any) => (
		userSignedIn
			? React.createElement(component, props)
			: <Redirect to='/login'/>
	)}/>;
};

export default ProtectedRoute;
